import React from "react";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import NftCard from "../NftCard";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import useGetMyNFTs from "../../hooks/useGetMyNFTs";
const MyNfts = () => {
  const { data, loading, search, setSearch } = useGetMyNFTs();

  let searched = data;

  if (search) {
    searched = searched.filter((s) =>
      String(s?.name).toLowerCase()?.includes(search?.toLowerCase())
    );
  }

  return (
    <Wrapper pageName="nft-listing-page">
      <NavbarTwo />
      <section className="nftListingSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="nftListingWrap"
          >
            <div className="nftListingHead">
              <div className="secHead mb-0">
                <h1 className="secTitle">MY NFT’S</h1>
              </div>
              <div className="nftListingFilter">
                <div className="form-group">
                  <i className="icon-search"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Players/Rarity..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="nftListingInr">
              <div className="nftListingBoxes">
                <div className="row">
                  {loading ? (
                    <p className="text-center p-3">Loading NFTs...</p>
                  ) : data?.length <= 0 ? (
                    <p className="text-center p-3">No NFTs found</p>
                  ) : null}
                  {searched?.map?.((d) => {
                    return (
                      <div className="col-xxl-2 col-lg-3 col-md-4 col-6">
                        <NftCard
                          name={d?.shortName}
                          id={d?.nftId}
                          type={d?.category}
                          roles={d?.roles}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default MyNfts;
