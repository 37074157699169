import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import routes from "../../constants/routesConstants";
const Navbar = () => {
  return (
    <header className="headerW">
      <div className="container">
        <div className="headerWLogo">
          <Link to={routes.ConnectWallet}>
            <img src={logo} alt="" />
          </Link>
        </div>
      </div>
    </header>
  );
};
export default Navbar;
