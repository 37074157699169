import React, { useState } from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import flagImg from "../../assets/img/england.jpg";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useGetUpcomingMatches from "../../hooks/useGetUpcomingMatches";
import Countdown from "react-countdown";
import moment from "moment/moment";
const CreateGame = () => {
  const { sportsId } = useParams();
  const { data, loading } = useGetUpcomingMatches(sportsId);
  const [selectedMatch, setSelectedMatch] = useState(0);
  const [name, setName] = useState("");
  const [focused, setFocused] = useState(false);

  return (
    <Wrapper pageName="match-selection-page">
      <NavbarTwo />
      <section className="matchSSec commonSec">
        <div className="container">
          <div className="matchSW">
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="matchSType"
            >
              <label>Enter game name</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={focused ? "" : "TYPE THE GAME NAME"}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                />
                <button type="button">
                  <i className="icon-arrow-right"></i>
                </button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="matchSList"
            >
              <div className="matchSTitle">Select Match</div>
              <ul>
                {data?.map((d) => {
                  return (
                    <li
                      key={d?.id}
                      role="button"
                      className={d?.id === selectedMatch ? "active" : ""}
                      onClick={() => setSelectedMatch(d?.id)}
                    >
                      <div className="selectMBoxInr">
                        <div className="selectMTeam">
                          <div className="selectMTeamCF">
                            <img src={d?.first_team_image} alt="" />
                            <span className="selectMCN">
                              {d?.first_team_short_name}
                            </span>
                          </div>
                          <div className="selectMCD">{d?.first_team_name}</div>
                        </div>
                        <div className="selectMTime">
                          {d?.start_date && (
                            <Countdown
                              date={moment(`${d?.start_date}T${d?.start_time}`)}
                              renderer={({ days, hours, minutes, seconds }) => {
                                return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
                              }}
                            />
                          )}
                        </div>
                        <div className="selectMTeam">
                          <div className="selectMTeamCF">
                            <span className="selectMCN">
                              {d?.second_team_short_name}
                            </span>
                            <img src={d?.second_team_image} alt="" />
                          </div>
                          <div className="selectMCD">{d?.second_team_name}</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="form-footer-btns"
            >
              <Link
                to={`${routes.CreatedGameConfirmation}/${sportsId}/${selectedMatch}/${name}`}
                className={`btn btn-primary ${
                  selectedMatch && name !== "" ? "" : "disabled"
                }`}
              >
                Proceed
              </Link>
              <Link to={routes.Dashboard} className="btn btn-danger">
                Exit
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
      <Footer btnType="sm" rightBtnLink={routes.Dashboard} />
    </Wrapper>
  );
};

export default CreateGame;
