import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import { motion } from "framer-motion";
const ExitModal = () => {
  const themeCtx = useContext(ThemeContext);
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const walletLogoutHandler = () => {
    themeCtx.toggleExitMHandler();
    logout();
    navigate(routes.ConnectWallet);
  };
  return (
    <>
      <div
        className={`boxModal ${themeCtx.isExitMOpen === true ? "show" : ""}`}
      >
        <div className="boxModalInr">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="confirmationBox"
          >
            <h1 className="confirmationBoxTitle">confirmation</h1>
            <div className="confirmationBoxText">
              do you wish to exit the game?
            </div>
            <div className="confirmationBoxBtns">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => walletLogoutHandler()}
              >
                Proceed
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => themeCtx.toggleExitMHandler()}
              >
                Cancle
              </button>
            </div>
          </motion.div>
        </div>
      </div>
      <div
        className={`boxModalBackdrop ${
          themeCtx.isExitMOpen === true ? "show" : ""
        }`}
      ></div>
    </>
  );
};
export default ExitModal;
