import React from "react";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import routes from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useGetMyMatches from "../../hooks/useGetMyMatches";
import moment from "moment";

const MyMatches = () => {
  const navigate = useNavigate();
  const { data, loading, setStatus, status } = useGetMyMatches();

  return (
    <Wrapper pageName="my-matches-page">
      <NavbarTwo />
      <section className="myMatchesSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 35 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="myMatchesC"
          >
            <div className="gameCreationCTitle">My Matches</div>
            <div className="myMatchesTabW">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => {
                    setStatus("Upcoming");
                  }}
                >
                  <button
                    className={`nav-link ${
                      status === "Upcoming" ? "active" : ""
                    }`}
                    id="pills-upcoming-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-upcoming"
                    type="button"
                    role="tab"
                    aria-controls="pills-upcoming"
                    aria-selected="true"
                  >
                    upcoming
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => {
                    setStatus("Live");
                  }}
                >
                  <button
                    className={`nav-link ${status === "Live" ? "active" : ""}`}
                    id="pills-live-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-live"
                    type="button"
                    role="tab"
                    aria-controls="pills-live"
                    aria-selected="false"
                  >
                    live
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => {
                    setStatus("Complete");
                  }}
                >
                  <button
                    className={`nav-link ${
                      status === "Complete" ? "active" : ""
                    }`}
                    id="pills-completed-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-completed"
                    type="button"
                    role="tab"
                    aria-controls="pills-completed"
                    aria-selected="false"
                  >
                    completed
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  role="tabpanel"
                  aria-labelledby="pills-upcoming-tab"
                >
                  <p className="text-center mt-3">
                    {loading ? "Loading..." : ""}
                    {!loading && data?.records?.length <= 0
                      ? "No Matches found"
                      : ""}
                  </p>
                  {data?.records?.map((record) => {
                    let matchData = {};
                    try {
                      matchData = JSON.parse(record?.description);
                    } catch (error) {}

                    return (
                      <div
                        className="myMb"
                        onClick={() => {
                          if (!record?.opponent) {
                            navigate(`${routes.InvitePlayer}/${record?.code}`);
                            return;
                          }

                          if (
                            record?.matchStatus === "Complete" &&
                            record?.opponent &&
                            record?.user
                          ) {
                            navigate(`${routes.Winner}/${record?.code}`);
                            return;
                          }

                          if (record?.opponent && record?.user) {
                            navigate(`${routes.Match}/${record?.code}`);
                            return;
                          }
                        }}
                      >
                        <div className="myMTabTitle">
                          <span>1v1</span>
                          <span>{record?.title}</span>
                        </div>
                        <div className="myMTeamW">
                          <div className="myMTB">
                            <div className="myMTBRow">
                              <div className="myMTBImg">
                                <img src={matchData?.first_team_image} alt="" />
                              </div>
                              <div className="myMTC">
                                <span className="myMTName">
                                  {record?.match?.team1}
                                </span>
                                <span className="myMTScore">
                                  {matchData?.scorecard}
                                </span>
                              </div>
                            </div>
                            <div className="myMTeamCreation">
                              <span>
                                {moment(matchData?.match_start_time_ms).format(
                                  "DD/MM/YY HH:mm a"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="myMTBolt">
                            <i className="icon-bolt"></i>
                          </div>
                          <div className="myMTB">
                            <div className="myMTBRow">
                              <div className="myMTBImg">
                                <img
                                  src={matchData?.second_team_image}
                                  alt=""
                                />
                              </div>
                              <div className="myMTC">
                                <span className="myMTName">
                                  {record?.match?.team2}
                                </span>
                                <span className="myMTScore">
                                  {matchData?.second_team_scorecard}
                                </span>
                              </div>
                            </div>
                            <div className="myMTeamCreation">
                              <span>
                                {moment(matchData?.match_start_time_ms).format(
                                  "DD/MM/YY HH:mm a"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="myMTeamCU">
                          <i className="icon-person"></i>
                          <span>
                            {record?.opponent && record?.user ? "2/2" : "1/2"}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" leftBtnHide />
    </Wrapper>
  );
};

export default MyMatches;
