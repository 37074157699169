import { useContext, useEffect, useState } from "react";
import API from "../api";
import AuthContext from "../context/authContext";

const useGetMyNFTs = () => {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  const refresh = async () => {
    try {
      setLoading(true);
      const myNFTs = await API.get(
        `/vendor-apis/my-nfts/${userData?.accountAddress}`
      );

      const myNFTsData = myNFTs?.data?.records ?? [];

      localStorage.setItem("myNFTs", JSON.stringify(myNFTsData));
      setData(myNFTsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return {
    refresh,
    loading,
    data,
    search,
    setSearch,
  };
};

export default useGetMyNFTs;
