import React from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import nftImg from "../../assets/img/nft-1.jpg";
import NftCard from "../NftCard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useGetMatchDetails from "../../hooks/useGetMaychDetails";
import Countdown from "react-countdown";
import moment from "moment";
import useCreateGame from "../../hooks/useCreateGame";
import useGetPlayerNFTDetailsFromLocal from "../../hooks/useGetPlayerNFTDetailsFromLocal";
import cubixCardImg from "../../assets/img/cubix-card.jpg";
import { toast } from "react-toastify";

const CreatedGameConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  let nfts = query?.get("nfts");
  nfts = nfts ? nfts?.split(",") : [];
  const selectedPlayerDetails = useGetPlayerNFTDetailsFromLocal(nfts);

  const { name, matchId, sportsId } = useParams();

  const { data } = useGetMatchDetails(matchId);

  const { loading: saving, mutate } = useCreateGame(name, matchId);

  const createGame = async () => {
    if (saving) {
      return;
    }
    if (!selectedPlayerDetails || selectedPlayerDetails?.length <= 0) {
      toast.error("Please select NFT");
      return;
    }

    const saved = await mutate({
      title: name,
      description: JSON.stringify(data),
      matchId: parseInt(matchId, 10),
      sportId: sportsId,
      matchTitle: data?.name,
      matchSubTitle: data?.tournament_name,
      matchDescription: data?.description,
      matchStatus: data?.match_status,
      team1: data?.first_team_name,
      team2: data?.second_team_name,
      startTime: moment(
        `${data?.start_date}T${data?.start_time}`
      ).toISOString(),
      nfts: nfts,
    });

    if (saved) {
      navigate(routes.MyMatches);
    }
  };

  return (
    <Wrapper pageName="game-creation-confirmation-page">
      <NavbarTwo />
      <section className="gameCreationSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 35 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="gameCreationInr"
          >
            <div className="gameCreationC">
              <div className="gameCreationCTitle">{name}</div>
              <div className="gameCreationSMatchW">
                <div className="gameCreationCTitle">Match Selected</div>
                <div className="selectMBoxInr">
                  <div className="selectMTeam">
                    <div className="selectMTeamCF">
                      <img src={data?.first_team_image} alt="" />
                      <span className="selectMCN">
                        {data?.first_team_short_name}
                      </span>
                    </div>
                    <div className="selectMCD">{data?.first_team_name}</div>
                  </div>
                  <div className="selectMTime">
                    <Countdown
                      date={moment(`${data?.start_date}T${data?.start_time}`)}
                      renderer={({ days, hours, minutes, seconds }) => {
                        return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
                      }}
                    />
                  </div>
                  <div className="selectMTeam">
                    <div className="selectMTeamCF">
                      <span className="selectMCN">
                        {data?.second_team_short_name}
                      </span>
                      <img src={data?.second_team_image} alt="" />
                    </div>
                    <div className="selectMCD">{data?.second_team_name}</div>
                  </div>
                </div>
              </div>
              <div className="gameCreationSNft">
                {nfts?.length > 0 ? (
                  <div className="gameCreationCTitle">
                    NFT selected
                    {selectedPlayerDetails?.map((selectedPlayerDetail) => {
                      return (
                        <Link
                          to={`${routes.NftListing}/${sportsId}/${data?.id}?return=${location.pathname}`}
                        >
                          <NftCard
                            name={selectedPlayerDetail?.shortName}
                            // rank="0000/10000"
                            image={nftImg}
                            id={selectedPlayerDetail?.nftId}
                            type={selectedPlayerDetail?.category}
                            roles={selectedPlayerDetail?.roles}
                          />
                          <p className="text-center text-white">Change NFT</p>
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <div className="p-5 text-center">
                    <Link
                      to={`${routes.NftListing}/${sportsId}/${data?.id}?return=${location.pathname}`}
                    >
                      <div className="connectingPNftCardImg">
                        <img src={cubixCardImg} alt="" />
                        <p className="text-center text-white">Select NFT</p>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="form-footer-btns">
              <a
                href="javascript:void(0)"
                onClick={createGame}
                className="btn btn-primary w-100"
              >
                {saving ? "Please wait..." : "create"}
              </a>
              {/* <a href="#" className="btn btn-primary">
                share
              </a> */}
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" rightBtnLink={routes.Dashboard} />
    </Wrapper>
  );
};

export default CreatedGameConfirmation;
