import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../api";
import routes from "../constants/routesConstants";

const useConnectingGames = (matchId, nftNo) => {
  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const connectingGame = async (data) => {
    try {
      setLoading(true);
      const response = await API.get(
        `/game-specific/connecting-player?matchId=${matchId}&nftNo=${nftNo}`
      );
      if (response?.data) {
        navigate(`${routes.ConfirmGame}/${response?.data}${location.search}`);
      } else {
        toast.error("No matching open team found");
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (matchId && nftNo) {
      connectingGame();
    }
  }, [matchId, nftNo]);

  return { connectingGame, loading };
};

export default useConnectingGames;
