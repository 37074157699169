import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../api";

const useGetCMS = () => {
  const { page } = useParams();

  useEffect(() => {
    if (page) {
      refresh();
    }
  }, [page]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const refresh = async () => {
    try {
      setLoading(true);
      const response = await API.get(`/vendor-apis/staticPage/${page}`);
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { refresh, loading, data };
};

export default useGetCMS;
