import React, { useContext, useEffect } from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import UserNWAvatar from "../UserNWAvatar";
import avatar from "../../assets/img/avatar.jpg";
import cubixCardImg from "../../assets/img/cubix-card.jpg";
import NftCard from "../NftCard";
import { Link, useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useGetGameDetails from "../../hooks/useGetGameDetails";
import useGetPlayerNFTDetailsFromLocal from "../../hooks/useGetPlayerNFTDetailsFromLocal";
import { toast } from "react-toastify";
import useJoinGame from "../../hooks/useJoinGame";
import AuthContext from "../../context/authContext";
import { getUserName } from "../../services/formatService";
const ConfirmGame = () => {
  const { userData } = useContext(AuthContext);

  const location = useLocation();

  const query = new URLSearchParams(location?.search);
  let nfts = query?.get("nfts");
  nfts = nfts ? nfts?.split(",") : [];
  const selectedPlayerDetailsTeam2 = useGetPlayerNFTDetailsFromLocal(nfts);

  const { code } = useParams();
  const { data, getDetails } = useGetGameDetails(false);

  const { mutate, loading } = useJoinGame();
  useEffect(() => {
    if (code) {
      getDetails(code);
    }
  }, [code]);

  useEffect(() => {
    if (
      data?.nfts?.[0]?.nftId &&
      selectedPlayerDetailsTeam2?.[0]?.nftId &&
      data?.nfts?.[0]?.nftId === selectedPlayerDetailsTeam2?.[0]?.nftId
    ) {
      toast.error("Cant select same card as opponent");
    }
  }, [data]);

  const selectedPlayerDetails = data?.nfts ?? [];

  const joinGame = () => {
    if (loading) {
      return;
    }
    mutate(
      {
        nfts,
        code,
      },
      data?._blockChainGameId
    );
  };

  if (data?.matchStatus && data?.matchStatus !== "Upcoming") {
    return (
      <Wrapper pageName="confirmation-game-page">
        <NavbarTwo />
        <section className="connectingWSec commonSec">
          <div className="container">
            <p className="text-center text-white color-white">
              You cant join this game now. Please check for another game. Thanks
            </p>
          </div>
        </section>
        <Footer
          btnType="sm"
          leftBtnHide={true}
          leftBtnLink={routes.NftListing}
          rightBtnLink={routes.Dashboard}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper pageName="confirmation-game-page">
      <NavbarTwo />
      <section className="connectingWSec commonSec">
        <div className="container">
          <div className="connectingWCon">
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="connectingPRow"
            >
              <div className="connectingPNftCard">
                {selectedPlayerDetails?.map((selectedPlayerDetail) => {
                  if (selectedPlayerDetail?.addedBy?.id !== data?.user?.id) {
                    return null;
                  }
                  return (
                    <NftCard
                      name={selectedPlayerDetail?.shortName}
                      // rank="0000/10000"
                      // image={nftImg}
                      id={selectedPlayerDetail?.nftId}
                      type={selectedPlayerDetail?.category}
                      roles={selectedPlayerDetail?.roles}
                    />
                  );
                })}
                {selectedPlayerDetails?.length <= 0 ? (
                  <div className="connectingPNftCardImg">
                    <img src={cubixCardImg} alt="" />
                  </div>
                ) : null}
              </div>

              <UserNWAvatar
                name={getUserName(data?.user)}
                image={data?.user?.profileImage ?? avatar}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="connectingPRow"
            >
              <div className="connectingPVsText my-4">VS</div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="connectingPRow justify-content-end"
            >
              <UserNWAvatar
                name={getUserName(userData)}
                image={userData?.profileImage ?? avatar}
              />
              <div className="connectingPNftCard">
                <Link
                  to={`${routes.NftListing}/${data?.sport?.internalComment}/${
                    data?.match?._id
                  }?return=${location.pathname}&s=${data?.nftsNo
                    ?.map((nft) => String(nft))
                    .join(",")}`}
                >
                  <div className="connectingPNftCardImg">
                    {selectedPlayerDetailsTeam2?.length > 0 ? (
                      selectedPlayerDetailsTeam2?.map(
                        (selectedPlayerDetail) => {
                          return (
                            <>
                              <NftCard
                                name={selectedPlayerDetail?.shortName}
                                // rank="0000/10000"
                                // image={nftImg}
                                id={selectedPlayerDetail?.nftId}
                                type={selectedPlayerDetail?.category}
                                roles={selectedPlayerDetail?.roles}
                              />
                              <p className="text-center text-white">
                                Change NFT
                              </p>
                            </>
                          );
                        }
                      )
                    ) : (
                      <>
                        <img src={cubixCardImg} alt="" />
                        <p className="text-center text-white">Select NFT</p>
                      </>
                    )}
                  </div>
                </Link>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.7 }}
              className="connectingPGBtns"
            >
              <a
                href="javascript:void(0)"
                className="btn btn-primary"
                onClick={joinGame}
              >
                {loading ? "Please wait..." : "CONFIRM"}
              </a>
              <Link
                to={`${routes.NftListing}/${data?.sport?.internalComment}/${
                  data?.match?._id
                }?return=${location.pathname}&s=${data?.nfts
                  ?.map((nft) => String(nft?.nftId))
                  .join(",")}`}
                className="btn-icon btn-primary"
              >
                <i className="icon-reset"></i>
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
      <Footer
        btnType="sm"
        leftBtnHide={true}
        leftBtnLink={routes.NftListing}
        rightBtnLink={routes.Dashboard}
      />
    </Wrapper>
  );
};

export default ConfirmGame;
