import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import useGetGameDetails from "../../hooks/useGetGameDetails";
const JoinMatch = () => {
  const { code, setCode, getDetails } = useGetGameDetails();

  return (
    <Wrapper>
      <NavbarTwo />
      <section className="joinGameSec commonSec">
        <div className="container">
          <div className="userProfileUpdateCon">
            <div className="userProfileUpdateInr">
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="form-group with-strip"
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Code"
                  onChange={(e) => setCode(e?.target?.value)}
                  value={code}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="form-footer-btns"
              >
                <a
                  className="btn btn-primary"
                  href="javascript:void(0)"
                  onClick={() => getDetails(code, true)}
                >
                  Proceed
                </a>
                <Link to={routes.Dashboard} className="btn btn-danger">
                  Exit
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default JoinMatch;
