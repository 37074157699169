const routes = {
  ConnectWallet: "/",
  Game: "/game",
  Dashboard: "/game/dashboard",
  ChooseGame: "/game/choose-game",
  SelectMatch: "/game/select-match",
  GameHistory: "/game/game-history",
  MyMatches: "/game/my-matches",
  NftListing: "/game/nft-listing",
  ConnectingGame: "/game/connecting-game",
  ConfirmGame: "/game/confirm-game",
  Match: "/game/match",
  Winner: "/game/winner",
  JoinMatch: "/game/join-match",
  ConfirmPrivateGame: "/game/confirm-private-game",
  UpdateProfile: "/game/update-profile",
  CreateGame: "/game/create-game",
  CreatedGameConfirmation: "/game/created-game-confirmation",
  InvitePlayer: "/game/invite-player",
  CMSPage: "/content-page",
  HowToPlay: "/how-to-play",
  MyNFTs: "/game/my-nfts",
};
export default routes;
