import React from "react";
import routes from "../../constants/routesConstants";
import useGetCMS from "../../hooks/useGetCMS";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";

const CMSPages = () => {
  const { data, loading } = useGetCMS();

  console.log({ data });
  return (
    <Wrapper>
      <NavbarTwo />
      <section className="commonSec">
        <div className="container">
          <div className="cmsB">
            {loading ? (
              <p className="text-center text-white mt-4 mb-4">Loading...</p>
            ) : (
              <div
                className="cmsCon"
                dangerouslySetInnerHTML={{ __html: data?.description || "" }}
              />
            )}
          </div>
        </div>
      </section>
      <Footer btnType="sm" rightBtnLink={routes.Dashboard} leftBtnHide={true} />
    </Wrapper>
  );
};

export default CMSPages;
