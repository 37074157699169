const useGetPlayerNFTDetailsFromLocal = (nftId) => {
  const myNFTs = localStorage.getItem("myNFTs");

  if (myNFTs) {
    const myNFTsJSON = JSON.parse(myNFTs);

    return myNFTsJSON?.filter((d) => nftId?.includes(String(d?.nftId)));
  }
};

export default useGetPlayerNFTDetailsFromLocal;
