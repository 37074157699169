import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import API from "../api";

const AuthContext = React.createContext({
  userData: {},
  isLoggedIn: false,
  login: async (data) => {},
  logout: () => {},
});

const retrieveStoredToken = () => {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  return {
    storedUserData: storedUserData,
  };
};

export const AuthContextProvider = (props) => {
  useEffect(() => {
    if (!window?.ethereum) {
      toast.error("Web3 wallet not found");
      return;
    }
    window?.ethereum?.on?.("accountsChanged", function (accounts) {
      logoutHandler();
    });
  }, []);

  const userDataOld = retrieveStoredToken();

  let initialUserData;
  if (userDataOld) {
    initialUserData = userDataOld.storedUserData;
  }

  const [userData, setUserData] = useState(initialUserData);

  const userIsLoggedIn = !!userData;

  const logoutHandler = useCallback(() => {
    setUserData();
    localStorage.removeItem("myNFTs");
    localStorage.removeItem("userData");
    localStorage.removeItem("x-token");
  }, []);

  const getRegisteredUserDetails = async (accountAddress) => {
    const response = await API.get(`/me`);

    const details = {
      accountAddress,
      ...response?.data,
    };

    localStorage.setItem("x-token", details?.access_token);
    localStorage.setItem("userData", JSON.stringify(details));

    // sync NFTs
    API.get(`/vendor-apis/sync-nfts/${accountAddress}`);

    const myNFTs = await API.get(`/vendor-apis/my-nfts/${accountAddress}`);

    const myNFTsData = myNFTs?.data?.records ?? [];

    localStorage.setItem("myNFTs", JSON.stringify(myNFTsData));
    setUserData(details);
    return true;
  };

  const loginHandler = async (data) => {
    try {
      if (data?.accountAddress) {
        const response = await API.post(`/login/${data?.accountAddress}`);
        localStorage.setItem("x-token", response?.data?.access_token);

        const responseDetails = await API.get(`/me`);

        const details = {
          ...response?.data,
          ...responseDetails?.data,
        };
        setUserData(details);
        localStorage.setItem("userData", JSON.stringify(details));

        if (!data?.silent) {
          // sync NFTs
          API.get(`/vendor-apis/sync-nfts/${data?.accountAddress}`);
        }

        return true;
      }
    } catch (error) {
      // silent connect error
      if (error?.message === "Already registered with cubix") {
        return await getRegisteredUserDetails(data?.accountAddress);
      }
    } finally {
    }
  };

  const contextValue = {
    userData: userData,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
