import { useEffect, useState } from "react";
import API from "../api";

const useGetSports = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const refresh = async () => {
    try {
      setLoading(true);
      const response = await API.get("/entity-resource/sports");
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return { refresh, loading, data };
};

export default useGetSports;
