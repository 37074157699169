import { ethers } from "ethers";
import {
  cardGameABI,
  CONTRACT_ADDRESS,
  nftABI,
} from "../constants/appConstant";

export const getCardGameContract = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    CONTRACT_ADDRESS.cardGame,
    cardGameABI,
    signer
  ).connect(signer);
  return contract;
};

export const getNFTContract = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(
    CONTRACT_ADDRESS.nft,
    nftABI,
    signer
  ).connect(signer);
  return contract;
};
