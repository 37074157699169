import { useContext, useEffect, useState } from "react";
import API from "../api";
import AuthContext from "../context/authContext";

const useGetMatchPlayerNFTs = (matchId, selectedNFTs) => {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [notAvailableNFTs, setNotAvailableNFTs] = useState([]);
  const [status, setStatus] = useState("upcoming");

  const refresh = async () => {
    try {
      setLoading(true);
      const response = await API.get(`/vendor-apis/match-players/${matchId}`);
      const myNFTs = await API.get(
        `/vendor-apis/my-nfts/${userData?.accountAddress}`
      );

      const myNFTsData = myNFTs?.data?.records ?? [];

      localStorage.setItem("myNFTs", JSON.stringify(myNFTsData));

      const availableNFTs = [];
      const notAvailableNFTs = [];

      myNFTsData?.forEach((nft) => {
        const available = response?.data?.some((d) => {
          if (
            selectedNFTs?.length > 0 &&
            selectedNFTs?.includes(String(nft?.no))
          ) {
            return false;
          }
          return (
            String(d?.player_nft_id) === String(nft?.no) &&
            String(d?.player_short_name) === String(nft?.shortName)
          );
        });

        if (available) {
          availableNFTs.push(nft);
        } else {
          notAvailableNFTs.push(nft);
        }
      });

      setData(availableNFTs);
      setNotAvailableNFTs(notAvailableNFTs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [status]);

  return {
    refresh,
    loading,
    data,
    status,
    setStatus,
    notAvailableNFTs,
    search,
    setSearch,
  };
};

export default useGetMatchPlayerNFTs;
