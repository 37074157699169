import React from "react";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";

import NftCard from "../NftCard";
import { motion } from "framer-motion";
import useGetMyMatchHistory from "../../hooks/useGetMyMatchHistory";
import moment from "moment/moment";
import { BLOCKCHAIN_SCAN_URL } from "../../constants/appConstant";

const GameHistory = () => {
  const { data, loading, userData } = useGetMyMatchHistory();

  return (
    <Wrapper pageName="match-history-page">
      <NavbarTwo />
      <section className="matHSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="matHC"
          >
            <div className="gameCreationCTitle">Match History</div>
            <div className="matHList">
              {loading ? <p className="text-center p-3">Loading...</p> : null}
              {!loading && data?.records?.length <= 0 ? (
                <p className="text-center p-3">No data found</p>
              ) : null}
              {data?.records?.map?.((d) => {
                let winnerNFT = d?.winningNFT;
                let winner = d?.winner;

                if (winner && winner?.id === userData?.id) {
                  winnerNFT = d?.nfts?.find(
                    (n) => n?.addedBy?.id !== userData?.id
                  );
                }

                if (winner && winner?.id !== userData?.id) {
                  // loser
                  winnerNFT = d?.nfts?.find(
                    (n) => n?.addedBy?.id === userData?.id
                  );
                }

                if (!winner) {
                  winner = userData;
                  winnerNFT = d?.nfts?.find(
                    (n) => n?.addedBy?.id === userData?.id
                  );
                }

                console.log({ winnerNFT, winner });
                return (
                  <div className="matHRow" key={d?.id}>
                    <div className="matHTransDetail">
                      {winner ? (
                        <NftCard
                          name={winnerNFT?.shortName}
                          id={winnerNFT?.nftId}
                          type={winnerNFT?.category}
                          roles={winnerNFT?.roles}
                        />
                      ) : null}
                      <div className="matHTransDetailC">
                        <span className="matHCType">{winnerNFT?.category}</span>
                        <span className="matHTransId">
                          ID#{winnerNFT?.nftId}
                        </span>
                        <span className="matHTransStatus">
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={`${BLOCKCHAIN_SCAN_URL}/tx/${d?.winningTxId}`}
                          >
                            TRANSACTION ID
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="matHCreation">
                      <span className="matHDT">
                        {moment(d?.lastChangedDateTime).format("DD/MM/YYYY")}
                      </span>
                      <span
                        className={`matHMStatus ${
                          winner?.id === userData?.id ? "" : "text-danger"
                        }`}
                      >
                        {d?.winner
                          ? winner?.id === userData?.id
                            ? "YOU WON"
                            : "YOU LOSE"
                          : "Draw"}
                      </span>
                      <span className="matHDT">
                        {moment(d?.lastChangedDateTime).format("hh:mm a")}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default GameHistory;
