import React, { useContext, useEffect } from "react";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import UserNWAvatar from "../UserNWAvatar";
import avatar from "../../assets/img/avatar-lg.jpg";
import NftCard from "../NftCard";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import useGetGameDetails from "../../hooks/useGetGameDetails";
import AuthContext from "../../context/authContext";
import { getUserName } from "../../services/formatService";
import useClaimWonNFTs from "../../hooks/useClaimWonNFTs";

const Winner = () => {
  const { userData } = useContext(AuthContext);

  const { code } = useParams();
  const { data, getDetails } = useGetGameDetails(false);

  const { claimWonNFTs, loading } = useClaimWonNFTs();
  useEffect(() => {
    if (code) {
      getDetails(code);
    }
  }, [code]);

  let gameDetails = {};
  try {
    gameDetails = JSON.parse(data?.description);
  } catch (error) {}

  const winningNFT = data.winningNFT ?? {};

  const selectedPlayerDetails = [winningNFT];

  let winner = data?.winner;

  if (winner && winner?.id === userData?.id) {
    selectedPlayerDetails[0] = data?.nfts?.find(
      (n) => n?.addedBy?.id !== userData?.id
    );
  }

  if (winner && winner?.id !== userData?.id) {
    // loser
    selectedPlayerDetails[0] = data?.nfts?.find(
      (n) => n?.addedBy?.id === userData?.id
    );
  }

  if (!winner) {
    selectedPlayerDetails[0] = data?.nfts?.find(
      (n) => n?.addedBy?.id === userData?.id
    );
  }
  return (
    <Wrapper pageName="winner-page">
      <NavbarTwo />
      <section className="gameLiveMSec commonSec">
        <div className="container">
          <div className="connectingWCon">
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="gameLiveMWrap"
            >
              <div className="gameLiveMHead">
                <div className="gameLiveMTitle">{data?.title}</div>
                <div className="gameLiveMStatus is-live">
                  <i className="icon-live"></i> {data?.matchStatus}
                </div>
              </div>
              <div className="gameLiveMBody">
                <div className="gameLiveMTeam">
                  <div className="gameLiveMTeamFlag">
                    <img src={gameDetails?.first_team_image} alt="" />
                  </div>
                  <div className="gameLiveMTeamCon">
                    <div className="gameLiveMTeamName">
                      {gameDetails?.first_team_name}
                    </div>
                    <div className="gameLiveMTeamScore">
                      {gameDetails?.scorecard}
                    </div>
                  </div>
                </div>
                <div className="gameLiveMBolt">
                  <i className="icon-bolt"></i>
                </div>
                <div className="gameLiveMTeam">
                  <div className="gameLiveMTeamFlag">
                    <img src={gameDetails?.second_team_image} alt="" />
                  </div>
                  <div className="gameLiveMTeamCon">
                    <div className="gameLiveMTeamName">
                      {gameDetails?.second_team_name}
                    </div>
                    <div className="gameLiveMTeamScore">
                      {gameDetails?.second_team_scorecard}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 25 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="gameWinnerWrap"
            >
              {winner?.id ? (
                <UserNWAvatar
                  image={winner?.profileImage ?? avatar}
                  name={getUserName(winner)}
                />
              ) : null}
              <div className="gameWinnerC">
                {selectedPlayerDetails?.map((s) => {
                  return (
                    <NftCard
                      name={s?.shortName}
                      // rank="0000/10000"
                      // image={nftImg}
                      id={s?.nftId}
                      type={s?.category}
                      roles={s?.roles}
                    />
                  );
                })}

                <div className="gameWinnerCF">
                  <div className="gameWinnerCText1">
                    {winner
                      ? userData?.id === winner?.id
                        ? "CONGRATULATIONS, YOU WON!!"
                        : "Hard luck, You lose NFT, try another game"
                      : "Draw"}
                  </div>
                  {/* <div className="gameWinnerCText2">WINNER!</div> */}
                </div>
              </div>
            </motion.div>

            {/* winner not set up game is completed, so can regain NFT back */}

            {data?.status === "Complete" && data?.winningTxId && !winner ? (
              <div className="form-footer-btns">
                <a
                  href="javascript:void(0)"
                  onClick={() => claimWonNFTs(data?._blockChainGameId)}
                  className="btn btn-primary w-100"
                >
                  {loading ? "Please wait..." : "Claim NFT"}
                </a>
              </div>
            ) : null}

            {/* winner can claim data */}
            {winner?.id === userData?.id &&
            data?.status !== "winner_nft_distributed" ? (
              <div className="form-footer-btns">
                <a
                  href="javascript:void(0)"
                  onClick={() => claimWonNFTs(data?._blockChainGameId)}
                  className="btn btn-primary w-100"
                >
                  {loading ? "Please wait..." : "Claim NFT"}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <Footer btnType="sm" leftBtnHide={true} />
    </Wrapper>
  );
};

export default Winner;
