import React from "react";
import Wrapper from "../Wrapper";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";
import avatar from "../../assets/img/avatar.jpg";
import { motion } from "framer-motion";

import useUpdateProfile from "../../hooks/useUpdateProfile";

const UpdateProfile = () => {
  const { loading, mutate, data, setData, setFile, preview } =
    useUpdateProfile();
  return (
    <Wrapper>
      <Navbar />
      <section className="userProfileUpdateSec commonSec">
        <div className="container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
          >
            <div className="userProfileUpdateCon">
              <div className="secHead">
                <motion.h1
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="secTitle"
                >
                  Update Profile
                </motion.h1>
              </div>
              <div className="userProfileUpdateInr">
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="form-profile-group"
                >
                  <img src={preview || data?.profileImage || avatar} alt="" />
                  <span>Change Profile Pic</span>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files?.[0])}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="form-group"
                >
                  <i className="icon-username"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={data?.userName}
                    value={data?.userName}
                    required
                    onChange={(e) =>
                      setData({
                        ...data,
                        userName: e.target.value,
                      })
                    }
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
                  className="form-group"
                >
                  <i className="icon-email"></i>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={data?.email}
                    onChange={(e) =>
                      setData({
                        ...data,
                        email: e.target.value,
                      })
                    }
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.9 }}
                  className="form-footer-btns"
                >
                  <button type="submit" className="btn btn-primary">
                    {loading ? "Please wait..." : "Confirm"}
                  </button>
                  <Link to={routes.Dashboard} className="btn btn-danger">
                    Exit
                  </Link>
                </motion.div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Wrapper>
  );
};

export default UpdateProfile;
