import { useState } from "react";
import { toast } from "react-toastify";
import API from "../api";
import { genericError } from "../constants/appConstant";
import useCreateGameInstance from "./useCreateGameInstance";

const useCreateGame = () => {
  const { createGameInstance, loading: creating } = useCreateGameInstance();
  const [loading, setLoading] = useState(false);

  const mutate = async (data) => {
    try {
      setLoading(true);
      const response = await API.post("/entity-resource/games", {
        ...data,
      });

      if (response?.data?.id) {
        const created = await createGameInstance(
          response?.data?._id,
          data?.nfts
        );

        if (created) {
          toast.success(
            `Game has been created successfully, game will be listed in My Matches page in ~1 minute,we are processing request`
          );
        }

        return created;
      }
      return false;
    } catch (error) {
      toast.error(
        error?.error?.data?.message || error?.message || genericError
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { mutate, loading: loading || creating };
};

export default useCreateGame;
