import AuthContext from "../context/authContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import routes from "../constants/routesConstants";
import useConnectWallet from "./useConnectWallet";

const useLogin = () => {
  const afterLogin = () => {
    navigate(routes.Dashboard, { replace: true });
  };

  const doLogin = async (accountAddress, silent = false) => {
    setLoading(true);
    let loaderW = 1;
    let loaderInterval = setInterval(() => {
      if (loaderW >= 100) {
        clearInterval(loaderInterval);
      } else {
        loaderW++;
        setLoaderWidth(loaderW);
      }
    }, 10);
    try {
      const result = await login({
        accountAddress,
        silent,
        cb: afterLogin,
      });
      if (result && !silent) {
        toast.success("Logged in successfully");
        afterLogin();
      }
    } catch (error) {
      setError(error);
      toast.error(error?.message);
    } finally {
      setLoading(false);
      setLoaderWidth(0);
    }
  };

  const navigate = useNavigate();
  const { login, userData, logout } = useContext(AuthContext);

  const { connect } = useConnectWallet(doLogin);

  const [, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loaderWidth, setLoaderWidth] = useState(0);

  const reset = () => {
    logout();
    setError();
    setLoading(false);
  };

  return {
    connect,
    reset,
    userData,
    loading,
    loaderWidth,
    setLoaderWidth,
    doLogin,
  };
};

export default useLogin;
