import React, { useContext, useEffect, useRef } from "react";
import avatar from "../../assets/img/avatar.jpg";
import ThemeContext from "../../context/themeContext";
import UserNWAvatar from "../UserNWAvatar";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import { getUserName } from "../../services/formatService";
import useGetMyNotifications from "../../hooks/useGetMyNotifications";
const NavbarTwo = () => {
  const themeCtx = useContext(ThemeContext);
  const menuRef = useRef(null);

  const { logout, userData } = useContext(AuthContext);

  const navigate = useNavigate();
  const walletLogoutHandler = () => {
    logout();
    navigate(routes.ConnectWallet);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (themeCtx.isMenuOpen) {
          themeCtx.toggleMenuHandler();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [themeCtx.isMenuOpen]);

  const { data, loading } = useGetMyNotifications();

  return (
    <header className="headerW">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <button
              type="button"
              className="btn-invisible"
              onClick={() => themeCtx.toggleProfileMHandler()}
            >
              <UserNWAvatar
                image={userData?.profileImage || avatar}
                name={getUserName(userData)}
              />
            </button>
          </div>
          <div className="col-auto">
            <div className="headerWBtns">
              <ul>
                <li>
                  <a
                    href="#"
                    className="btn-primary"
                    onClick={() => themeCtx.toggleNotificationHandler()}
                  >
                    <i className="icon-bell"></i>
                    {/* <div className="isNew">
                      <i className="icon-exclamation"></i>
                    </div> */}
                  </a>
                  <div className="sub-menu">
                    <motion.ul
                      style={{
                        height: 290,
                        overflowY: "auto",
                      }}
                      initial={{ opacity: 0, y: 25 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className={`${themeCtx.isNotificationOpen ? "show" : ""}`}
                    >
                      {loading ? (
                        <p className="text-center p-3">Loading...</p>
                      ) : null}

                      {!loading && data?.records?.length <= 0 ? (
                        <p className="text-center p-3">
                          No Notifications found
                        </p>
                      ) : null}
                      {data?.records?.map?.((record) => {
                        return (
                          <li key={record?.id} className="p-3">
                            <span>{record?.title}</span>
                          </li>
                        );
                      })}
                    </motion.ul>
                  </div>
                </li>
                <li ref={menuRef}>
                  <a
                    href="#"
                    className="btn-primary"
                    onClick={() => themeCtx.toggleMenuHandler()}
                  >
                    <i
                      className={`icon-${
                        themeCtx.isMenuOpen ? "close" : "bars"
                      }`}
                    ></i>
                  </a>
                  <div className="sub-menu">
                    <motion.ul
                      initial={{ opacity: 0, y: 25 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className={`${themeCtx.isMenuOpen ? "show" : ""}`}
                    >
                      <li>
                        <Link
                          to={`${routes.MyNFTs}`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        >
                          <i className="icon-point-system"></i>
                          <span>My NFTs</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={routes.HowToPlay}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        >
                          <i className="icon-how-to-play"></i>
                          <span>How To Play</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${routes.CMSPage}/points-system`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        >
                          <i className="icon-point-system"></i>
                          <span>Point System</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${routes.CMSPage}/privacy-policy`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        >
                          <i className="icon-privacy-policy"></i>
                          <span>Privacy Policy</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={`${routes.CMSPage}/about-us`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        
                        >
                          <i className="icon-about-us"></i>
                          <span>About us</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to={`${routes.CMSPage}/about-us`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        
                        >
                          <i className="icon-legality"></i>
                          <span>Legality</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to={`${routes.CMSPage}/terms-and-conditions`}
                          onClick={() => themeCtx.toggleMenuHandler()}
                        >
                          <i className="icon-terms"></i>
                          <span>Terms & Conditions</span>
                          <i className="icon-arrow-right"></i>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() => walletLogoutHandler()}
                        >
                          <i className="icon-logout"></i>
                          <span>Logout</span>
                          <i className="icon-arrow-right"></i>
                        </a>
                      </li>
                    </motion.ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default NavbarTwo;
