import React from "react";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";

const Footer = ({
  btnType = "lg",
  leftBtnHide = false,
  leftBtnText = "",
  leftBtnLink = "",
  rightBtnText = "",
  rightBtnLink = "",
}) => {
  const navigate = useNavigate();
  return (
    <footer className="footerW">
      <div className="container">
        {btnType === "lg" ? (
          <div className="row">
            {leftBtnHide === false && (
              <div className="col">
                <Link to={leftBtnLink} className="btn btn-primary">
                  {leftBtnText}
                </Link>
              </div>
            )}
            <div className="col-auto">
              <Link to={rightBtnLink} className="btn btn-primary">
                {rightBtnText}
              </Link>
            </div>
          </div>
        ) : (
          <div className="row">
            {leftBtnHide === false && (
              <div className="col">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn-icon btn-primary">
                  <i className="icon-back"></i>
                </button>
              </div>
            )}
            <div className="col-auto">
              <Link to={routes.Dashboard} className="btn-icon btn-primary">
                <i className="icon-home"></i>
              </Link>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
