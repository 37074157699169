import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../api";
import AuthContext from "../context/authContext";
import { getUserName } from "../services/formatService";
import useLogin from "./useLogin";
import useUploadFile from "./useUploadFile";

const useUpdateProfile = () => {
  const { mutate: upload, loading: uploading } = useUploadFile();
  const { doLogin } = useLogin();
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ...userData,
    userName: getUserName(userData),
  });

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const mutate = async () => {
    try {
      setLoading(true);

      let uploadedFile;
      if (file) {
        uploadedFile = await upload(file);
      }
      await API.put(`/entity-resource/users/${userData?.id}`, {
        ...data,
        accountAddress: userData?.accountAddress,
        profileImage: uploadedFile?.data?.url,
      });

      doLogin(userData?.accountAddress, true);
      toast.success("Profile updated successfully");
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    mutate,
    loading: loading || uploading,
    setData,
    data,
    setFile,
    file,
    preview,
  };
};

export default useUpdateProfile;
