import React from "react";
const UserNWAvatar = ({ image, name }) => {
  return (
    <div className="headerWUserNameWrap">
      <img src={image} alt="" />
      <span className="headerWUserName">{name}</span>
    </div>
  );
};
export default React.memo(UserNWAvatar);
