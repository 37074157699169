import React from "react";
import { Link, useParams } from "react-router-dom";
import flagImg from "../../assets/img/england.jpg";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import useGetUpcomingMatches from "../../hooks/useGetUpcomingMatches";
import Countdown from "react-countdown";
import moment from "moment";
const SelectMatch = () => {
  const { sportsId } = useParams();
  const { data, loading } = useGetUpcomingMatches(sportsId);

  return (
    <Wrapper>
      <NavbarTwo />
      <section className="selectMSec commonSec">
        <div className="container">
          <div className="selectMCon">
            <div className="secHead">
              <motion.h1
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="secTitle"
              >
                select match
              </motion.h1>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="selectMBoxes"
            >
              {data?.map((d, index) => {
                return (
                  <Link to={`${routes.NftListing}/${sportsId}/${d?.id}`}>
                    <div className="selectMBox">
                      <span className="selectMName">{d?.name}</span>
                      <div className="selectMBoxInr">
                        <div className="selectMTeam">
                          <div className="selectMTeamCF">
                            <img src={d?.first_team_image} alt="" />
                            <span className="selectMCN">
                              {d?.first_team_short_name}
                            </span>
                          </div>
                          <div className="selectMCD">{d?.first_team_name}</div>
                        </div>
                        <div className="selectMTime">
                          <Countdown
                            date={moment(`${d?.start_date}T${d?.start_time}`)}
                            renderer={({ days, hours, minutes, seconds }) => {
                              return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
                            }}
                          />
                        </div>
                        <div className="selectMTeam">
                          <div className="selectMTeamCF">
                            <span className="selectMCN">
                              {d?.second_team_short_name}
                            </span>
                            <img src={d?.second_team_image} alt="" />
                          </div>
                          <div className="selectMCD">{d?.second_team_name}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </motion.div>
          </div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default SelectMatch;
