import React, { useEffect } from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import UserNWAvatar from "../UserNWAvatar";
import avatar from "../../assets/img/avatar.jpg";
import NftCard from "../NftCard";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useGetGameDetails from "../../hooks/useGetGameDetails";
import { gameDetailsReloadTime } from "../../constants/appConstant";
import { getUserName } from "../../services/formatService";

const Match = () => {
  const { code } = useParams();
  const { data, getDetails, stats } = useGetGameDetails(false, true);

  useEffect(() => {
    let interval;
    if (code) {
      interval = setInterval(() => {
        getDetails(code);
      }, gameDetailsReloadTime);
    }
    getDetails(code);

    return () => interval && clearInterval(interval);
  }, [code]);

  const navigate = useNavigate();

  let gameDetails = {};
  try {
    gameDetails = JSON.parse(data?.description);
  } catch (error) {}

  const nfts = data?.nfts ?? [];

  return (
    <Wrapper pageName="match-page">
      <NavbarTwo />
      <section className="gameLiveMSec commonSec">
        <div className="container">
          <div className="connectingWCon">
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="gameLiveMWrap"
            >
              <div className="gameLiveMHead">
                <div className="gameLiveMTitle">{data?.title}</div>
                <div className="gameLiveMStatus is-live">
                  <i className="icon-live"></i> {gameDetails?.match_status}
                </div>
              </div>
              <div className="gameLiveMBody">
                <div className="gameLiveMTeam">
                  <div className="gameLiveMTeamFlag">
                    <img src={gameDetails?.first_team_image} alt="" />
                  </div>
                  <div className="gameLiveMTeamCon">
                    <div className="gameLiveMTeamName">
                      {gameDetails?.first_team_name}
                    </div>
                    <div className="gameLiveMTeamScore">
                      {gameDetails?.scorecard}
                    </div>
                  </div>
                </div>
                <div className="gameLiveMBolt">
                  <i className="icon-bolt"></i>
                </div>
                <div className="gameLiveMTeam">
                  <div className="gameLiveMTeamFlag">
                    <img src={gameDetails?.second_team_image} alt="" />
                  </div>
                  <div className="gameLiveMTeamCon">
                    <div className="gameLiveMTeamName">
                      {gameDetails?.second_team_name}
                    </div>
                    <div className="gameLiveMTeamScore">
                      {gameDetails?.second_team_scorecard}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="connectingPWrap">
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="connectingPRow"
              >
                <div className="connectingPNftCard with-points">
                  <NftCard
                    name={nfts?.[0]?.shortName}
                    // rank="0000/10000"
                    // image={nftImg}
                    id={nfts?.[0]?.nftId}
                    type={nfts?.[0]?.category}
                    roles={nfts?.[0]?.roles}
                  />
                  <div className="connectingPNftCardPoints is-positive">
                    points: +{stats?.[nfts?.[0]?._id]}
                  </div>
                </div>
                <UserNWAvatar
                  image={data?.user?.profileImage ?? avatar}
                  name={getUserName(data?.user)}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.7 }}
                className="connectingPRow"
              >
                <div className="connectingPVsText my-4">VS</div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.9 }}
                className="connectingPRow justify-content-end"
              >
                <UserNWAvatar
                  image={data?.opponent?.profileImage ?? avatar}
                  name={getUserName(data?.opponent)}
                />
                <div className="connectingPNftCard with-points">
                  <NftCard
                    name={nfts?.[1]?.shortName}
                    // rank="0000/10000"
                    // image={nftImg}
                    id={nfts?.[1]?.nftId}
                    type={nfts?.[1]?.category}
                    roles={nfts?.[1]?.roles}
                  />
                  <div className="connectingPNftCardPoints">
                    points: +{stats?.[nfts?.[1]?._id]}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Footer btnType="sm" leftBtnHide={true} />
    </Wrapper>
  );
};

export default Match;
