import React from "react";
import { Link, useParams } from "react-router-dom";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";

const ConfirmPrivateGame = () => {
  const { code } = useParams();
  return (
    <Wrapper>
      <NavbarTwo />
      <section className="confirmationSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 35 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="confirmationBox"
          >
            <h1 className="confirmationBoxTitle">confirmation</h1>
            <div className="confirmationBoxText">
              do you wish to join the private game?
            </div>
            <div className="confirmationBoxBtns">
              <Link
                to={`${routes.ConfirmGame}/${code}`}
                className="btn btn-primary"
              >
                Proceed
              </Link>
              <Link to={routes.JoinMatch} className="btn btn-danger">
                Exit
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default ConfirmPrivateGame;
