import { useEffect, useState } from "react";
import API from "../api";

const useGetMatchDetails = (matchId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const refresh = async () => {
    try {
      setLoading(true);
      const response = await API.get(`/vendor-apis/matches/${matchId}`);
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (matchId) {
      refresh(matchId);
    }
  }, [matchId]);

  return { refresh, loading, data };
};

export default useGetMatchDetails;
