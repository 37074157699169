import { toast } from "react-toastify";
import { useState } from "react";

const ethers = require("ethers");

const useConnectWallet = (cb = (data) => data) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [accountAddress, setAccountAddress] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const checkConnectedAccount = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const accountAddress = await signer.getAddress();
      return accountAddress;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const connect = async () => {
    try {
      reset();
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const accountAddress = await signer.getAddress();
      setAccountAddress(accountAddress);
      cb?.(accountAddress);
    } catch (error) {
      if (error?.reason === "missing provider") {
        toast.error("Web3 wallet not found");
      } else {
        setError(error);
        toast.error(error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    connect,
    reset,
    error,
    loading,
    accountAddress,
    checkConnectedAccount,
  };
};

export default useConnectWallet;
