import React from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import NftCard from "../NftCard";
import Wrapper from "../Wrapper";
import nftImg from "../../assets/img/nft-1.jpg";
import { Link, useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useGetMatchPlayerNFTs from "../../hooks/useGetMatchPlayerNFTs";
const NftListing = () => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);

  const returns = query?.get("return");
  let selectedNFTs = query?.get("s");

  selectedNFTs = selectedNFTs ? selectedNFTs.split(",") : [];

  const { matchId } = useParams();
  const { data, loading, notAvailableNFTs, search, setSearch } =
    useGetMatchPlayerNFTs(matchId, selectedNFTs);

  let searched = data;

  if (search) {
    searched = searched.filter((s) =>
      String(s?.name).toLowerCase()?.includes(search?.toLowerCase())
    );
  }

  return (
    <Wrapper pageName="nft-listing-page">
      <NavbarTwo />
      <section className="nftListingSec commonSec">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="nftListingWrap"
          >
            <div className="nftListingHead">
              <div className="secHead mb-0">
                <h1 className="secTitle">MY NFT’S</h1>
              </div>
              <div className="nftListingFilter">
                <div className="form-group">
                  <i className="icon-search"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Players/Rarity..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                {/* <div className="form-group">
                  <select className="form-select">
                    <option>RARITY: HIGH TO LOW</option>
                    <option>RARITY: LOW TO HIGH</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="nftListingInr">
              <div className="nftListingBoxes">
                <div className="row">
                  {loading ? (
                    <p className="text-center p-3">Loading NFTs...</p>
                  ) : data?.length <= 0 ? (
                    <p className="text-center p-3">No NFTs found</p>
                  ) : null}
                  {searched?.map?.((d) => {
                    return (
                      <div className="col-xxl-2 col-lg-3 col-md-4 col-6">
                        <Link
                          to={`${
                            returns
                              ? `${`${returns}?nfts=${d?.nftId}`}`
                              : `${routes.ConnectingGame}/${matchId}/${d?.no}?nfts=${d?.nftId}`
                          }`}
                        >
                          <NftCard
                            name={d?.shortName}
                            id={d?.nftId}
                            type={d?.category}
                            roles={d?.roles}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="nftListingHead">
                <div className="secHead mb-0">
                  <h1 className="secTitle">
                    unlock nft’s by visiting{" "}
                    <span>
                      <a
                        href="https://cubixpro.world/"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        marketplace
                      </a>
                    </span>
                  </h1>
                </div>
              </div>
              <div className="nftListingBoxes">
                <div className="row">
                  {notAvailableNFTs?.map((d) => {
                    return (
                      <div className="col-xxl-2 col-lg-3 col-md-4 col-6">
                        <NftCard
                          name={d?.shortName}
                          rank={d?.rank}
                          id={d?.nftId}
                          type={d?.category}
                          roles={d?.roles}
                          isDisable={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default NftListing;
