import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChooseGame from "./components/ChooseGame";
import CMSPages from "./components/CMSPages";
import ConfirmGame from "./components/ConfirmGame";
import ConfirmPrivateGame from "./components/ConfirmPrivateGame";
import ConnectingGame from "./components/ConnectingGame";
import ConnectWallet from "./components/ConnectWallet";
import CreatedGameConfirmation from "./components/CreatedGameConfirmation";
import CreateGame from "./components/CreateGame";
import Dashboard from "./components/Dashboard";
import GameHistory from "./components/GameHistory";
import HowToPlay from "./components/HowToPlay";
import InvitePlayer from "./components/InvitePlayer";
import JoinMatch from "./components/JoinGame";
import Match from "./components/Match";
import MyMatches from "./components/MyMatches";
import MyNfts from "./components/MyNfts";
import NftListing from "./components/NftListing";
import NotFound from "./components/NotFound";
import PrivateRoutes from "./components/PrivateRoutes";
import SelectMatch from "./components/SelectMatch";
import UpdateProfile from "./components/UpdateProfile";
import Winner from "./components/Winner";
import routes from "./constants/routesConstants";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.ConnectWallet} element={<ConnectWallet />} exact />
        <Route path={`${routes.CMSPage}/:page`} element={<CMSPages />} exact />
        <Route path={routes.Game} element={<PrivateRoutes />}>
          <Route path={routes.Dashboard} element={<Dashboard />} exact />
          <Route path={`${routes.ChooseGame}/:flow`} element={<ChooseGame />} />
          <Route
            path={`${routes.SelectMatch}/:sportsId`}
            element={<SelectMatch />}
            exact
          />
          <Route
            path={`${routes.NftListing}/:sportsId/:matchId`}
            element={<NftListing />}
            exact
          />
          <Route path={`${routes.MyNFTs}`} element={<MyNfts />} exact />

          {/* pending */}
          <Route
            path={`${routes.ConnectingGame}/:matchId/:nftNo`}
            element={<ConnectingGame />}
            exact
          />
          <Route
            path={`${routes.ConfirmGame}/:code`}
            element={<ConfirmGame />}
            exact
          />
          <Route path={`${routes.Match}/:code`} element={<Match />} exact />
          <Route path={`${routes.Winner}/:code`} element={<Winner />} exact />
          <Route path={routes.GameHistory} element={<GameHistory />} exact />
          <Route path={routes.MyMatches} element={<MyMatches />} exact />
          <Route path={routes.JoinMatch} element={<JoinMatch />} exact />
          <Route
            path={`${routes.ConfirmPrivateGame}/:code`}
            element={<ConfirmPrivateGame />}
            exact
          />
          {/* pending */}
          <Route
            path={routes.UpdateProfile}
            element={<UpdateProfile />}
            exact
          />
          <Route
            path={`${routes.CreateGame}/:sportsId`}
            element={<CreateGame />}
            exact
          />
          <Route
            path={`${routes.CreatedGameConfirmation}/:sportsId/:matchId/:name`}
            element={<CreatedGameConfirmation />}
            exact
          />
          <Route
            path={`${routes.InvitePlayer}/:code`}
            element={<InvitePlayer />}
            exact
          />
        </Route>
        <Route path={routes.HowToPlay} element={<HowToPlay />} exact />
        <Route path="*" element={<NotFound />} replace />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
