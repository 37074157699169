import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../api";
import { gameDetailsReloadTime } from "../constants/appConstant";
import routes from "../constants/routesConstants";
import AuthContext from "../context/authContext";

const useGetMyMatches = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location?.search);
  const statusIs = query.get("status");

  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [status, setStatus] = useState(statusIs ?? "Upcoming");

  const refresh = async (showLoader = true) => {
    try {
      if (showLoader) {
        setLoading(true);
      }
      const response = await API.get(
        `entity-resource/my-matches?status=${status}&user=${userData?.id}&order[lastChangedDateTime]=DESC`
      );
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;

    if (status === "Live") {
      interval = setInterval(() => {
        refresh(false);
      }, gameDetailsReloadTime);
    }
    refresh();

    navigate({
      pathname: routes.MyMatches,
      search: `?status=${status}`,
    });

    return () => interval && clearInterval(interval);
  }, [status]);

  return { refresh, loading, data, status, setStatus };
};

export default useGetMyMatches;
