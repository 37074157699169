import React, { useContext, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import UserNWAvatar from "../UserNWAvatar";
import avatar from "../../assets/img/avatar.jpg";
import cubixCardImg from "../../assets/img/cubix-card.jpg";

import NftCard from "../NftCard";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import AuthContext from "../../context/authContext";
import useGetGameDetails from "../../hooks/useGetGameDetails";
import { getUserName } from "../../services/formatService";
import { toast } from "react-toastify";
import { domainName } from "../../constants/appConstant";
import useClaimWonNFTs from "../../hooks/useClaimWonNFTs";

const InvitePlayer = () => {
  const { userData } = useContext(AuthContext);
  const { code } = useParams();
  const { data, getDetails } = useGetGameDetails(false);

  const { claimUnusedNFTs, loading } = useClaimWonNFTs();

  useEffect(() => {
    if (code) {
      getDetails(code);
    }
  }, [code]);

  const selectedPlayerDetails = [];

  if (data?.nfts?.[0]) {
    selectedPlayerDetails[0] = data?.nfts?.[0];
  }

  console.log({ selectedPlayerDetails });

  return (
    <Wrapper pageName="invite-game-page">
      <NavbarTwo />
      <section className="connectingWSec commonSec">
        <div className="container">
          <div className="connectingWCon">
            <motion.div
              initial={{ opacity: 0, y: 35 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="connectingPRow"
            >
              <div className="connectingPNftCard">
                {selectedPlayerDetails?.map((selectedPlayerDetail) => {
                  return (
                    <NftCard
                      name={selectedPlayerDetail?.shortName}
                      // rank="0000/10000"
                      // image={nftImg}
                      id={selectedPlayerDetail?.nftId}
                      type={selectedPlayerDetail?.category}
                      roles={selectedPlayerDetail?.roles}
                    />
                  );
                })}
                {selectedPlayerDetails?.length <= 0 ? (
                  <div className="connectingPNftCardImg">
                    <img src={cubixCardImg} alt="" />
                  </div>
                ) : null}
              </div>
              <UserNWAvatar
                name={getUserName(userData)}
                image={userData?.profileImage || avatar}
              />
            </motion.div>
            {data?.matchStatus === "Upcoming" ? (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="connectingPRow"
                >
                  <div className="connectingPVsText my-4">VS</div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="connectingPRow justify-content-end"
                >
                  <UserNWAvatar name="NO PLAYER" image={avatar} />
                  <div className="connectingPNftCard"></div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 35 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
                  className="connectingPGBtns is-single"
                >
                  <CopyToClipboard
                    text={`${domainName}game/join-match?code=${code}`}
                    onCopy={() => {
                      toast.success("Invite link copied!");
                    }}
                  >
                    <a href="javascript:void(0)" className="btn btn-primary">
                      INVITE
                    </a>
                  </CopyToClipboard>
                </motion.div>
              </>
            ) : (
              <>
                {selectedPlayerDetails?.[0]?.status === "hold" ? (
                  <motion.div
                    initial={{ opacity: 0, y: 35 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                    className="connectingPGBtns is-single"
                  >
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary"
                      onClick={() => claimUnusedNFTs(data?._blockChainGameId)}
                    >
                      {loading ? "Please wait..." : "Get NFT back"}
                    </a>
                  </motion.div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </section>
      <Footer btnType="sm" rightBtnLink={routes.Dashboard} />
    </Wrapper>
  );
};

export default InvitePlayer;
