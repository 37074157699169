import React from "react";
import { Link, useParams } from "react-router-dom";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
import useGetSports from "../../hooks/useGetSports";
const ChooseGame = () => {
  const { flow } = useParams();
  const { data } = useGetSports();

  return (
    <Wrapper>
      <NavbarTwo />
      <section className="startWSec commonSec">
        <div className="container">
          <div className="startWBtns">
            <ul>
              {data?.records?.map((record, index) => {
                return (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, y: 35 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 * index }}
                  >
                    <Link
                      to={
                        flow === "join"
                          ? `${routes.SelectMatch}/${record?.internalComment}`
                          : `${routes.CreateGame}/${record?.internalComment}`
                      }
                      className="btn btn-primary btn-md"
                    >
                      {record?.name}
                    </Link>
                  </motion.li>
                );
              })}
              <motion.li
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Link to={routes.Dashboard} className="btn btn-danger btn-md">
                  EXIT
                </Link>
              </motion.li>
            </ul>
          </div>
        </div>
      </section>
      <Footer
        leftBtnLink={`${routes.GameHistory}?status=Complete`}
        leftBtnText="game history"
        rightBtnLink={routes.MyMatches}
        rightBtnText="my matches"
      />
    </Wrapper>
  );
};

export default ChooseGame;
