import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import routes from "../constants/routesConstants";
import { getCardGameContract } from "../services/web3Service";

const useClaimWonNFTs = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const claimWonNFTs = async (gameId) => {
    return new Promise(async (resolve, reject) => {
      try {
        reset();
        setLoading(true);
        const contract = await getCardGameContract();
        const claimed = await contract.functions.claimWonNFTs([gameId]);
        claimed.wait(3).then(() => {
          toast.success("Cheers!!, You have claimed won NFTs for this game.");
          navigate(routes.MyMatches);
          setLoading(false);
          resolve(true);
        });
      } catch (error) {
        setError(error);
        reject(error);
        setLoading(false);
      }
    });
  };

  const claimUnusedNFTs = async (gameId) => {
    return new Promise(async (resolve, reject) => {
      try {
        reset();
        setLoading(true);
        const contract = await getCardGameContract();
        const claimed = await contract.functions.claimUnusedNFTs([gameId]);
        claimed.wait(3).then(() => {
          toast.success(
            "Cheers!!, You have claimed NFTs for this game back to your wallet."
          );
          navigate(routes.Dashboard);
          setLoading(false);
          resolve(true);
        });
      } catch (error) {
        setError(error);
        reject(error);
        setLoading(false);
      }
    });
  };

  return {
    claimWonNFTs,
    reset,
    error,
    loading: loading,
    claimUnusedNFTs,
  };
};

export default useClaimWonNFTs;
