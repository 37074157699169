import React, { useContext } from "react";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar.jpg";
import routes from "../../constants/routesConstants";
import ThemeContext from "../../context/themeContext";
import { motion } from "framer-motion";
import { getUserName } from "../../services/formatService";
import AuthContext from "../../context/authContext";
const ProfileModal = () => {
  const themeCtx = useContext(ThemeContext);
  const { userData } = useContext(AuthContext);
  return (
    <>
      <div
        className={`boxModal ${themeCtx.isProfileMOpen === true ? "show" : ""}`}
      >
        <motion.div
          initial={{ opacity: 0, y: 35 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="boxModalInr"
        >
          <button
            type="button"
            className="boxModalCloseBtn"
            onClick={() => themeCtx.toggleProfileMHandler()}
          >
            <i className="icon-close"></i>
          </button>
          <div className="profileC">
            <div className="profileUW">
              <div className="profileUImg">
                <img src={userData?.profileImage || avatar} alt="" />
              </div>
              <div className="profileUC">
                <div className="profileUNameW">
                  <div className="profileUName">{getUserName(userData)}</div>
                  <Link to={routes.UpdateProfile} className="profileUNEditBtn">
                    <i className="icon-edit"></i>
                  </Link>
                </div>
                <div className="profileUWalletID">
                  {userData?.accountAddress}
                </div>
              </div>
            </div>
            <div className="profileCStats">
              <div className="profileCStatsTitle">Career Stats</div>
              <div className="profileCStatsBoxes">
                <div className="profileCStatsBox">
                  <span className="profileCStatsCount">000</span>
                  <p className="profileCStatsCountTitle">Contests</p>
                </div>
                <div className="profileCStatsBox">
                  <span className="profileCStatsCount">
                    {userData?.totalGames}
                  </span>
                  <p className="profileCStatsCountTitle">Matches</p>
                </div>
                <div className="profileCStatsBox">
                  <span className="profileCStatsCount">000</span>
                  <p className="profileCStatsCountTitle">Series</p>
                </div>
                <div className="profileCStatsBox">
                  <span className="profileCStatsCount">
                    {userData?.wonGames}
                  </span>
                  <p className="profileCStatsCountTitle">Wins</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div
        className={`boxModalBackdrop ${
          themeCtx.isProfileMOpen === true ? "show" : ""
        }`}
      ></div>
    </>
  );
};
export default ProfileModal;
