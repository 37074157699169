import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../api";
import routes from "../constants/routesConstants";
import useGetMatchPlayerStats from "./useGetMatchPlayerStats";

const useGetGameDetails = (redirect = true, statstics = false) => {
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const codeIs = query?.get("code");

  const {
    refresh: getStats,
    data: stats,
    loading: statsLoading,
  } = useGetMatchPlayerStats();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(codeIs || "");
  const [data, setData] = useState({});

  useEffect(() => {
    if (codeIs) {
      getDetails(codeIs, true);
    }
  }, [code]);

  const getDetails = async (code, joinProcess = false) => {
    try {
      setLoading(true);
      const response = await API.get(
        `entity-resource/games?relations[]=winningNFT&relations[]=nfts&relations[]=nfts.addedBy&relations[]=match&relations[]=sport&relations[]=user&relations[]=opponent&relations[]=winner&where[code]=${code}`
      );

      if (response?.data?.records?.length <= 0) {
        toast.error("Invalid game code");
        setLoading(false);

        return;
      }

      if (joinProcess && !response?.data?.records?.[0]?.isActive) {
        toast.error("Game is not open as of now to join");
        setLoading(false);
        return;
      }
      setData(response?.data?.records?.[0]);

      if (statstics && response?.data?.records?.[0]?.nfts?.length > 0) {
        console.log({ response });
        getStats(
          response?.data?.records?.[0]?.match?._id,
          response?.data?.records?.[0]?.nfts?.map((n) => n?._id)
        );
      }
      if (response?.data?.records?.[0] && redirect) {
        navigate(
          `${routes.ConfirmPrivateGame}/${response?.data?.records?.[0]?.code}`
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { getDetails, loading, code, setCode, data, stats, statsLoading };
};

export default useGetGameDetails;
