import { useState } from "react";
import { getCardGameContract } from "../services/web3Service";
import useApproveAllNFT from "./useApproveAllNFT";

const useCreateGameInstance = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { checkApproved, approve, loading: approving } = useApproveAllNFT();

  const reset = () => {
    setError();
    setLoading(false);
  };

  const createGameInstance = async (gameDbId, nfts) => {
    return new Promise(async (resolve, reject) => {
      try {
        reset();
        setLoading(true);
        let isApproved = await checkApproved(nfts?.[0]);
        if (!isApproved) {
          isApproved = await approve();
        }

        if (isApproved) {
          const contract = await getCardGameContract();
          const createdGame = await contract.functions.createGame(
            gameDbId,
            nfts
          );
          createdGame.wait(3).then(() => {
            setLoading(false);
            resolve(true);
          });
        } else {
          resolve(false);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
        throw new Error(error);
      }
    });
  };

  return {
    createGameInstance,
    reset,
    error,
    loading: loading || approving,
  };
};

export default useCreateGameInstance;
