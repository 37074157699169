import React from "react";
// import trophyImg from "../../assets/img/svg/trophy.svg";

const categoryClasses = {
  Legendary: "legendary",
  Rare: "rare",
  "Super Rare": "rare",
  Common: "common",
};

const NftCard = ({
  type = "",
  id = "",
  image = "",
  rank = "",
  name = "",
  isDisable = false,
  roles,
}) => {
  const category = categoryClasses?.[type];
  let roleImage;

  if (roles?.includes("Bowler")) {
    roleImage = `/images/Bowler.png`;
  }

  if (roles?.includes("Batsman")) {
    roleImage = `/images/Batsman.png`;
  }

  if (roles?.includes("Allrounder")) {
    roleImage = `/images/AllRounder.png`;
  }

  if (roles?.includes("WK")) {
    roleImage = `/images/WicketKeeper.png`;
  }

  return (
    <div className={`nft-card ${isDisable == true ? "is-disable" : ""}`}>
      <div className="nft-card-header">
        <div className={`nft-card-type ${category}`}>{type}</div>
        <div className="nft-card-id">ID #{id}</div>
      </div>
      <div className="nft-card-body">
        <div className={`nft-card-image nft-${id}`}>
          {id !== "0000000" ? (
            <img
              src={`https://apix.cubixpro.io/nfts/assets/${id}.jpg`}
              alt=""
            />
          ) : null}
        </div>
        <div className="nft-card-rank">
          {/* <img src={trophyImg} alt="" /> */}
          {/* <span>RANK: {rank}</span> */}
        </div>
        <h6 className="nft-card-name">{name}</h6>
      </div>
      <style jsx="true" global="true">
        {`
          .nft-card-image.nft-${id}::before {
            background-image: url(${roleImage});
            background-size: 14px;
          }
        `}
      </style>
    </div>
  );
};
export default NftCard;
