import { useState } from "react";
import API from "../api";

const useGetMatchPlayerStats = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const refresh = async (matchId, playerIds) => {
    try {
      setLoading(true);
      const response = await API.post(
        `/vendor-apis/match-player-points/${matchId}`,
        {
          playerIds,
        }
      );
      setData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { refresh, loading, data };
};

export default useGetMatchPlayerStats;
