import React from "react";
import Wrapper from "../Wrapper";
import metaMaskLogo from "../../assets/img/metamask-logo.svg";
import Navbar from "../Navbar";
import useLogin from "../../hooks/useLogin";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";

const ConnectWallet = () => {
  const {
    connect,
    loading: isConnecting,
    loaderWidth,
    setLoaderWidth,
  } = useLogin();

  const onConnectWHandler = () => {
    setLoaderWidth(35);
    connect();
  };

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Navbar />
      <section className="connectWSec commonSec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="connectWCon text-center">
                <div className="connectWBrand">
                  <motion.img
                    src={metaMaskLogo}
                    alt=""
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  />
                  <motion.span
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="connectWBrandText"
                  >
                    Connect with Metamask
                  </motion.span>
                </div>
                <div className="secHead">
                  <motion.h1
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.1 }}
                    className="secTitle"
                  >
                    Play the Blockchain-based <b>P2P Card Game</b>
                    <div>
                      , to get a chance to <span>Win</span> &{" "}
                      <span>Collect</span> NFT’s Card
                    </div>
                  </motion.h1>
                </div>
                {isConnecting ? (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="loadingWrap"
                  >
                    <div
                      className="loadingInr"
                      style={{ width: loaderWidth + "%" }}
                    >
                      <span>Loading...</span>
                    </div>
                  </motion.div>
                ) : (
                  <motion.button
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    type="button"
                    className="btn btn-primary btn-lg"
                    onClick={() => onConnectWHandler()}
                  >
                    Connect Wallet
                  </motion.button>
                )}
                <div>
                  <motion.button
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    type="button"
                    className="btn btn-primary btn-lg mt-5"
                    onClick={() => navigate(routes.HowToPlay)}
                  >
                    How to play
                  </motion.button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default ConnectWallet;
