import AuthContext from "../context/authContext";
import { toast } from "react-toastify";

import { getNFTContract } from "../services/web3Service";
import { CONTRACT_ADDRESS } from "../constants/appConstant";

const { useState, useContext } = require("react");

const useApproveAllNFT = () => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isApproved, setApproved] = useState(false);

  const checkApproved = async (nftId) => {
    return new Promise(async (resolve) => {
      try {
        reset();
        setLoading(true);
        const contract = await getNFTContract();
        const isApprovedForAll = await contract.functions.isApprovedForAll(
          userData?.accountAddress,
          CONTRACT_ADDRESS.cardGame
        );
        if (isApprovedForAll?.[0] === true) {
          setApproved(true);
          resolve(true);
          return;
        }

        const approved = await contract.functions.getApproved(nftId);
        console.log({ approved });
        const alreadyApproved =
          String(approved?.[0]).toLowerCase() ===
          CONTRACT_ADDRESS.cardGame?.toLowerCase();
        setApproved(alreadyApproved);
        resolve(alreadyApproved);
        // check approve
      } catch (error) {
        setError(error);
        toast.error(error?.message);
        resolve(false);
      } finally {
        setLoading(false);
      }
    });
  };

  const reset = () => {
    setError();
    setLoading(false);
  };

  const approve = async () => {
    return new Promise(async (resolve) => {
      try {
        reset();
        setLoading(true);
        const contract = await getNFTContract();
        const approved = await contract.setApprovalForAll(
          CONTRACT_ADDRESS.cardGame,
          true
        );
        approved.wait(3).then(() => {
          toast.success(`All NFTs approved, now you can proceed ahead.`);
          setLoading(false);
          resolve(true);
        });
      } catch (error) {
        setError(error);
        toast.error(
          error?.error?.data?.message ||
            error?.message ||
            "Something went wrong!"
        );
        setLoading(false);
        resolve(false);
      }
    });
  };

  return {
    approve,
    reset,
    userData,
    error,
    loading,
    logout,
    isApproved,
    checkApproved,
  };
};

export default useApproveAllNFT;
