import { useEffect, useState } from "react";
import API from "../api";

const useGetUpcomingMatches = (sportsId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const refresh = async () => {
    try {
      setLoading(true);
      const response = await API.get(
        `/vendor-apis/upcoming-matches/${sportsId}`
      );
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sportsId) {
      refresh(sportsId);
    }
  }, [sportsId]);

  return { refresh, loading, data };
};

export default useGetUpcomingMatches;
