import { useState } from "react";
import { toast } from "react-toastify";
import API from "../api";

const useUploadFile = () => {
  const [loading, setLoading] = useState(false);

  const mutate = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await API.post(`/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return { mutate, loading };
};

export default useUploadFile;
