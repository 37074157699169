import { useContext, useEffect, useState } from "react";
import API from "../api";

import AuthContext from "../context/authContext";

const useGetMyMatchHistory = () => {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const refresh = async (showLoader = true) => {
    try {
      if (showLoader) {
        setLoading(true);
      }
      const response = await API.get(
        `entity-resource/my-matches?status=Complete&user=${userData?.id}&order[lastChangedDateTime]=DESC`
      );
      setData(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return { refresh, loading, data, userData };
};

export default useGetMyMatchHistory;
