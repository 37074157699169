import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ThemeContext from "../../context/themeContext";
import ExitModal from "../ExitModal";
import ProfileModal from "../ProfileModal";

const Wrapper = ({ pageName = "", children }) => {
  const location = useLocation();
  const themeCtx = useContext(ThemeContext);
  useEffect(() => {
    if (themeCtx.isProfileMOpen) {
      themeCtx.toggleProfileMHandler();
    }
  }, [location]);
  return (
    <>
      <div className={`wrapper ${pageName}`}>{children}</div>
      <ExitModal />
      <ProfileModal />
    </>
  );
};

export default Wrapper;
