import React, { useContext } from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";
import ThemeContext from "../../context/themeContext";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import { motion } from "framer-motion";
const Dashboard = () => {
  const themeCtx = useContext(ThemeContext);
  return (
    <Wrapper>
      <NavbarTwo />
      <section className="startWSec commonSec">
        <div className="container">
          <div className="startWBtns">
            <ul>
              <motion.li
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Link
                  to={`${routes.ChooseGame}/join`}
                  className="btn btn-primary btn-md"
                >
                  play now
                </Link>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Link
                  to={`${routes.ChooseGame}/create`}
                  className="btn btn-primary btn-md"
                >
                  create Game
                </Link>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Link to={routes.JoinMatch} className="btn btn-primary btn-md">
                  join game
                </Link>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.7 }}
              >
                <a
                  href="#"
                  className="btn btn-danger btn-md"
                  onClick={() => themeCtx.toggleExitMHandler()}
                >
                  EXIT
                </a>
              </motion.li>
            </ul>
          </div>
        </div>
      </section>
      <Footer
        leftBtnLink={`${routes.GameHistory}?status=Complete`}
        leftBtnText="game history"
        rightBtnLink={routes.MyMatches}
        rightBtnText="my matches"
      />
    </Wrapper>
  );
};

export default Dashboard;
