import React from "react";
import routes from "../../constants/routesConstants";
import Footer from "../Footer";
// import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import htp1 from "../../assets/img/htp-1.jpg";
import htp2 from "../../assets/img/htp-2.jpg";
import htp3 from "../../assets/img/htp-3.jpg";
import htp4 from "../../assets/img/htp-4.jpg";
import htp5 from "../../assets/img/htp-5.jpg";
import htp6 from "../../assets/img/htp-6.jpg";
import htp7 from "../../assets/img/htp-7.jpg";
import htp8 from "../../assets/img/htp-8.jpg";

const HowToPlay = () => {
  return (
    <Wrapper pageName="nft-listing-page">
      {/* <NavbarTwo /> */}
      <section className="howToPlayW commonSec">
        <div className="container">
          <div className="howToPlayB">
            <div className="secHead text-center">
              <h1 className="secTitle">HOW TO PLAY cubix p2p card battle</h1>
            </div>
            <div className="howToPlayInr">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp1} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">
                        Step 1: Connect Wallet
                      </div>
                      <div className="cmsCon">
                        <p>
                          Connect the Metamask wallet that holds your CUBIX's
                          NFT.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp2} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">Step 2: Select Game</div>
                      <div className="cmsCon">
                        <p>
                          Select the game you wish to play, then press the Play
                          Now button if you would like to play against a random
                          player. Create a private game, press the Create Game
                          button. Join a private game, press the Join Game
                          button.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp3} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">
                        Step 3: Pick a Sport
                      </div>
                      <div className="cmsCon">
                        <p>Select the sport you want to battle in.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp4} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">
                        Step 4: Pick A Match
                      </div>
                      <div className="cmsCon">
                        <p>Choose an upcoming match you want to compete in.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp5} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">Step 5: Choose NFT</div>
                      <div className="cmsCon">
                        <p>
                          Select the NFT you'd like to use for battle. To
                          participate, you'll need at least 1 NFT; you can buy
                          one from{" "}
                          <a href="https://www.cubixpro.io" target="_blank">
                            www.cubixpro.io
                          </a>{" "}
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp6} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">Step 6: Join Room</div>
                      <div className="cmsCon">
                        <p>
                          After the opponent joins the room, press confirm to
                          begin the battle. You can press refresh to compete
                          again with a different opponent.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp7} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">
                        Step 7: NFT's performance
                      </div>
                      <div className="cmsCon">
                        <p>
                          Based on the performance of real-life players, you can
                          keep track of your NFT’s performance in the joined
                          battle.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="howToPlayC">
                    <div className="howToPlayCImg">
                      <img src={htp8} alt="" />
                    </div>
                    <div className="howToPlayCFooter">
                      <div className="howToPlayCTitle">
                        Step 8: Battle result
                      </div>
                      <div className="cmsCon">
                        <p>
                          You can win or lose NFT based on real-life player
                          performance. If you win, the NFT will be transferred
                          automatically to you; if you lose, the NFT will be
                          transferred automatically to the opponent.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer
        btnType="sm"
        rightBtnLink={routes.ConnectWallet}
        leftBtnHide={true}
      />
    </Wrapper>
  );
};

export default HowToPlay;
