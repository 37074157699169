import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

const ThemeContext = createContext({
  isMenuOpen: false,
  toggleMenuHandler: () => {},
  isExitMOpen: false,
  toggleExitMHandler: () => {},
  isProfileMOpen: false,
  toggleProfileMHandler: () => {},
  isNotificationOpen: false,
  toggleNotificationHandler: () => {},
});

export const ThemeContextProvider = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isExitMOpen, setIsExitMOpen] = useState(false);
  const [isProfileMOpen, setIsProfileMOpen] = useState(false);

  const toggleMenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleExitMHandler = () => {
    setIsExitMOpen(!isExitMOpen);
  };

  const toggleProfileMHandler = () => {
    setIsProfileMOpen(!isProfileMOpen);
  };

  const toggleNotificationHandler = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const contextValue = {
    isMenuOpen: isMenuOpen,
    toggleMenuHandler: toggleMenuHandler,
    isExitMOpen: isExitMOpen,
    toggleExitMHandler: toggleExitMHandler,
    isProfileMOpen: isProfileMOpen,
    toggleProfileMHandler: toggleProfileMHandler,
    toggleNotificationHandler,
    isNotificationOpen: isNotificationOpen,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
