import React from "react";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";
import Wrapper from "../Wrapper";
import UserNWAvatar from "../UserNWAvatar";
import avatar from "../../assets/img/avatar.jpg";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import useConnectingGames from "../../hooks/useConnectingGames";
const ConnectingGame = () => {
  const { matchId, nftNo } = useParams();

  const { loading } = useConnectingGames(matchId, nftNo);

  return (
    <Wrapper>
      <NavbarTwo />
      <section className="connectingWSec commonSec">
        <div className="container">
          <div className="connectingWCon">
            <div className="connectingPWrap">
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="connectingPRow"
              >
                <UserNWAvatar name="username" image={avatar} />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="connectingPRow"
              >
                <div className="connectingPVsText">VS</div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 35 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="connectingPRow justify-content-end"
              >
                <div>
                  <UserNWAvatar name="username" image={avatar} />
                  <div className="connectingPBadgeText">
                    {loading ? "CONNECTING..." : "CONNECTING..."}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Footer btnType="sm" />
    </Wrapper>
  );
};

export default ConnectingGame;
