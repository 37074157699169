import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import routes from "../../constants/routesConstants";
import useConnectWallet from "../../hooks/useConnectWallet";
import useLogin from "../../hooks/useLogin";

const PrivateRoutes = () => {
  const { checkConnectedAccount, loading } = useConnectWallet();
  const { doLogin } = useLogin();

  const [checkingAccount, setCheckingAccount] = useState(true);
  const [connectAccount, setConnectedAccount] = useState("");

  useEffect(() => {
    checkAccount();
  }, []);

  const checkAccount = async () => {
    try {
      setCheckingAccount(true);
      const accountAddress = await checkConnectedAccount();
      setConnectedAccount(accountAddress);
      await doLogin(accountAddress, true);
      setCheckingAccount(false);
    } catch (error) {
    } finally {
      setCheckingAccount(false);
    }
  };
  const renderPage = () => {
    const userData = localStorage.getItem("userData");
    if (loading || checkingAccount) {
      return null;
    }
    if (userData) {
      const json = JSON.parse(userData);
      if (json?.accountAddress === connectAccount) {
        return <Outlet />;
      }
    }
    return <Navigate to={routes.ConnectWallet} replace />;
  };

  return (
    <>
      {loading || checkingAccount ? (
        <div className={`page-loading ${loading ? "show" : ""}`}>
          <span className="sm-loader"></span>
        </div>
      ) : null}
      {renderPage()}
    </>
  );
};

export default PrivateRoutes;
