import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import routes from "../constants/routesConstants";
import useJoinGameInstance from "./useJoinGameInstance";

const useJoinGame = () => {
  const navigate = useNavigate();

  const { joinGameInstance, loading: joining } = useJoinGameInstance();

  const [loading, setLoading] = useState(false);

  const mutate = async (data, gameId) => {
    try {
      setLoading(true);
      const joined = await joinGameInstance(gameId, data?.nfts);
      if (joined) {
        toast.success(
          `Game has been created successfully, game will be listed in My Matches page in ~1 minute,we are processing request`
        );
        navigate(`${routes.MyMatches}`);
      }
    } catch (error) {
      toast.error(error?.error?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return { mutate, loading: loading || joining };
};

export default useJoinGame;
